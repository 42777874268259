@import 'src/styles/services';

.root {
  background-color: transparent;
  border-radius: 0;

  &.arrow {
    :global(.tippy-content) {
      padding: 0 0 1px;
    }

    :global(.tippy-arrow) {
      color: $color-black;

      &[data-hide] {
        display: none;
      }
    }
  }

  &.error {
    :global(.tippy-arrow) {
      color: $color-red;

      &[data-hide] {
        display: none;
      }
    }
  }

  &.window {
    :global(.tippy-content) {
      padding: 0;
    }

    :global(.tippy-arrow) {
      display: none;
    }
  }
}

.content {
  font-weight: 400;
  background: $color-black;
  border-radius: 10px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: $color-grey-light;
  padding: 8px 16px;

  .window & {
    color: inherit;
    background: $color-black-light;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    padding: 8px;
  }
}

@import 'src/styles/services';

.description {
  position: relative;
  z-index: 2;
  color: $color-white;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.title {
  position: relative;
  z-index: 2;
  text-align: center;
  color: $color-yellow;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}

.disableMessage {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: $color-white;
  text-align: center;
  margin-top: 8px;
}

.mediumSize {
  &.root {
    flex-grow: 1;
  }

  .title {
    color: $color-yellow;
    font-weight: 900;
    font-size: 15px;
    line-height: 15px;
    text-align: left;
    max-width: calc(100% - var(--icon-size) - 10px);
  }

  .description {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    max-width: calc(100% - var(--icon-size) - 10px);
    text-align: start;
    margin-top: 0;
  }
}

@include media-width-down($screen-lg) {
  .title {
    font-size: 26px;
    line-height: 40px;
  }

  .description {
    font-size: 18px;
    line-height: 24px;
  }

  .disableMessage {
    font-size: 18px;
    line-height: 24px;
  }
}

@include media-width-down($screen-sm) {
  .title {
    line-height: 32px;
  }

  .description {
    margin-top: 3px;
    font-size: 16px;
    line-height: 20px;
  }
}

@include media-width-down($screen-xs) {
  .title {
    font-size: 18px;
  }

  .description {
    font-size: 13px;
  }
}

@import 'src/styles/services';

.root {
  padding-top: 15px;
  height: 100%;
}

.loader {
  position: relative;
  min-height: 200px;
}

.methods {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.methodsWrapper {
  display: flex;
  position: relative;

  &.methodsWrapperVisible::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 48px;
    background: linear-gradient(180deg, transparent 0%, $color-black-light 100%);
  }
}

.content {
  display: flex;
  height: 100%;

  .methodsWrapper,
  .form {
    width: 50%;
  }

  .form {
    overflow-y: auto;
    padding-left: 16px;
    padding-right: 10px;
  }

  @include media-width-down($screen-1032) {
    .methodsWrapper {
      width: 100%;
    }

    .methods {
      width: 100%;
      padding-inline: 0;

      @include remove-scrollbar();
    }

    .form {
      display: none;
      padding-inline: 0;

      @include remove-scrollbar();
    }

    &.hasSelected {
      .methodsWrapper {
        display: none;
      }

      .form {
        display: block;
        width: 100%;
      }
    }
  }
}

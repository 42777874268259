@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 587px;
}

.icon {
  display: none;
  position: absolute;
  margin-top: -28px;
  width: 144px;
  height: 144px;
  background-image: url('/assets/icons/images/accountGold.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
  margin-bottom: 15px;
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
  text-align: center;
  color: $color-white;
  text-transform: uppercase;
}

.text {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: $color-white;
}

.buttonContainer {
  display: grid;
  margin-top: 30px;
  width: 100%;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: auto;
  grid-auto-flow: dense;

  .button {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@include media-width-down($screen-xs) {
  .root {
    width: 100%;
  }

  .icon {
    margin-top: -22px;
    width: 120px;
    height: 120px;
  }

  .header {
    margin-top: 95px;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 29px;
  }

  .text {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  .buttonContainer {
    margin-top: 15px;
    gap: 15px;
  }
}

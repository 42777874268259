@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.medium {
    width: 388px;
  }

  &.small {
    width: 296px;
  }

  @include media-width-down($screen-xs) {
    &.medium, &.small {
      width: 100%;
    }
  }
}

.imageContainer {
  position: relative;
  height: 135px;
  width: 100%;
}

.imageWrapper {
  position: absolute;
  width: calc(100% + 32px);
  height: calc(100% + 32px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header, .bonus {
  @include title(h3, 900);

  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
}

.header {
  color: $color-yellow;
}

.bonus {
  color: $color-red;
}

.message {
  @include text(xl, 400);

  text-align: center;
  color: $color-white;
}

.yellowText {
  color: $color-yellow;
}

.redText {
  color: $color-red;
}

.bold {
  font-weight: 500;
}

.button {
  font-size: 22px;
  font-weight: 900;
}
@import 'src/styles/services';

.root:global(.ps) {
  :global {
    .ps__rail-y {
      &,
      &.ps--clicking,
      &:hover {
        left: auto !important; // stylelint-disable-line declaration-no-important
        width: 4px;
        background-color: $color-black;

        .ps__thumb-y {
          right: 0;
          width: 4px;
          background-color: $color-red;
        }
      }
    }

    .ps__rail-x {
      &,
      &.ps--clicking,
      &:hover {
        height: 4px;
        background-color: $color-black;

        .ps__thumb-x {
          bottom: 0;
          height: 4px;
          background-color: $color-red;
        }
      }
    }
  }

  &:hover {
    :global(.ps__rail-y) {
      opacity: 1;
    }
  }
}

.mobile {
  overflow: auto;
  height: 100%;
}

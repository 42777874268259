@import 'src/styles/services';

.button {
  position: relative;
  border-radius: $border-sm;
  transition: trs(border-radius, background-color, color);

  &.cover > svg {
    width: 100%;
    height: 100%;
  }
}

.rounded {
  border-radius: 50%;
}

@import 'src/styles/services';

.root {
  gap: 16px;
}

.wrapper {
  max-width: 328px;
  width: 100%;
}

.iconWrapper, .title {
  margin: 0;
}

.icon {
  color: $color-white;
  width: 40px;
  height: 40px;
}

.description {
  @include text();

  text-align: center;
  color: rgba($color-white, 0.6);
}

.email {
  color: $color-white;
  text-decoration: none;
}

@include media-width-down($screen-360) {
  .wrapper {
    max-width: calc(100% - 32px);
  }
}

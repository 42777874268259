@import 'src/styles/services';

.root {
  margin-top: 16px;

  .button.confirm {
    &:before,
    &:after {
      background-color: #007b00;
    }
  }
}

.phoneMessage {
  color: #b7b4bc;
  margin-bottom: 16px;
  text-align: center;
}

.field {
  margin-bottom: 16px;
}

.button {
  & + .button {
    margin-top: 16px;
  }
}

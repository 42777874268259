@import 'src/styles/services';

.rules {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 15px;
    letter-spacing: 0.05em;
    color: $color-red;
  }

  .title {
    font-weight: 400;
    font-size: 13px;
    color: $color-white;
  }
}

.root {
  border-radius: 10px;
  padding: 16px;
  position: relative;
  overflow: hidden;
  width: 344px;
  display: flex;
  gap: 8px;
  cursor: pointer;

  @include media-width-down($screen-xs) {
    width: 260px;
  }

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    background: url(/assets/bonus/images/bonusItemBg.webp);
    background-size: 100px 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &.active {
    border: 1px solid $color-red;

    &::after {
      content: '';
      height: 100%;
      width: 100%;
      background: radial-gradient(50% 50% at 50% 100%, rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  &.comingSoon {
    pointer-events: none;

    .text {
      color: $color-yellow;
    }
  }
}

.image {
  height: 42px;
  width: 61px;
}

@import 'src/styles/services';

.link {
  color: inherit;
  text-decoration: underline;

  @include hover {
    text-decoration: none;
  }
}

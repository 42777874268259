@import 'src/styles/services';

.discount {
  position: absolute;
  top: -1px;
  right: -1px;
  padding: 4px;
  background-color: $color-black-light;
  border-radius: 0 $border-lg 0 $border-lg;
  font-size: 8px;
  line-height: 10px;
  color: #fcf501;
  border-top: 1px solid $color-black-light;
  border-right: 1px solid $color-black-light;
  transition: trs(border-color);
}

.root {
  min-height: 96px;
  width: 100%;
  padding: 10px 16px;
  background-color: $color-black;
  border: 1px solid $color-black;
  transition: trs(border-color);
  white-space: initial;

  @include hover {
    &,
    .discount {
      border-color: $color-red;
    }
  }

  &.active {
    background: radial-gradient(50% 50% at 50% 100%, rgba($color-red, 0.5) 0%, rgba(255, 0, 0, 0) 100%), $color-black;

    &,
    .discount {
      border-color: $color-red;
    }
  }

  @include media-width-down($screen-xs) {
    min-height: 76px;
    padding: 7px;
  }
}

.text {
  font-style: normal;
  color: $color-white;
  width: 124px;

  @include media-width-down($screen-xs) {
    width: 82px;
  }
}

.footer {
  margin-top: 8px;
  font-size: 13px;
  line-height: 16px;
}

.image {
  position: relative;
  width: 119px;
  min-height: 68px;

  @include media-width-down($screen-xs) {
    width: 82px;
    min-height: 52px;
  }
}

@import 'src/styles/services';

.root {
  margin-top: 16px;
}

.errorMessage {
  text-align: center;
  color: $color-red;
}

.button {
  margin-top: 16px;
}

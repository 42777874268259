@import 'src/styles/services';

.root {
  position: relative;

  &.hidden {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  }
}

.quickTips {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0;
  gap: 8px;

  .quickItem {
    @include text(xs);

    flex: 1 1 0;
    overflow: initial;
    background-color: $color-black-light;
    padding: 10px 8px;
    color: $color-white;

    &.active {
      background-color: $color-red;
    }

    @include hover {
      &:not(.active) {
        color: $color-red;
        text-shadow: none;
      }
    }
  }
}

.caption {
  @include text(sm, 900);

  color: $color-black;
  text-transform: uppercase;

  &.above {
    margin-bottom: 8px;
  }

  &.below {
    margin-top: 8px;
  }
}

.loaderWrapper {
  position: relative;
  height: 48px;
  border: 1px solid $form-field-outlined-border-color;
  border-radius: $border-sm;
}

.loader {
  left: 45%;
  width: 2rem;
}
@import 'src/styles/services';

$half: calc(50% - 10px);

.root {
  position: relative;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .rulesButton {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    padding: 0;
    text-decoration: underline;
    text-transform: inherit;
    vertical-align: baseline;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.buttonContainer,
.terms,
.promoCode {
  grid-column: 1 / -1;
}

.terms {
  background-color: $color-black;
  padding: 16px;
  border-radius: $border-lg;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.size310 {
  &.buttonContainer {
    flex-direction: column;
  }

  .rowWrap {
    flex-wrap: wrap;
  }
}

.input {
  font-weight: 900;
  font-size: 15px;
}

.isEmpty .input {
  font-weight: 400;
}

.checkbox input:not(:checked) ~ .checkboxText {
  color: $color-grey;
}

.acceptAllText {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}

.row {
  display: flex;
  gap: 16px;

  &.size310 {
    flex-wrap: wrap;
  }
}

.prefixIcon {
  color: $color-grey;
  width: 20px;
  height: 20px;
}

.selectSmall {
  width: 100%;
  min-width: 128px;
}

.password {
  [type='password'] {
    letter-spacing: 5px;
  }
}

.phoneField {
  flex: 1;
}

.datePicker,
.dateInput {
  height: 48px;
  font-size: 15px;

  :global(.react-datepicker-wrapper),
  :global(.react-datepicker__input-container),
  :global([class*='FormField_root']) :global([class*='FormFieldBase-module_componentWrapper']) {
    height: 100%;
  }
}

@include media-width-down($screen-xs) {
  .root,
  .row:not(.phoneField) {
    gap: 12px;
    flex-direction: column;
  }

  .row.phoneField {
    gap: 12px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .buttonContainer {
    flex-direction: column;
  }
}

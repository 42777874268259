@import '../helpers/variables';
@import '../mixins/media-not-touch-screen';

@mixin remove-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scrollbar-shadow($shadow-color: $color-black, $direction: 'vertical') {
  $angle: 0deg;

  @if $direction == 'vertical' {
    $angle: 360deg;

    width: 100%;
    height: 90px;
  } @else if $direction == 'horizontal' {
    $angle: 90deg;

    width: 90px;
    height: 100%;
  }

  content: '';
  position: absolute;
  z-index: $slider-shadow-z-index;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: linear-gradient($angle, $shadow-color 0%, rgb(0 0 0 / 0%) 100%);
  pointer-events: none;
  transition: trs(opacity);
}

@mixin custom-scrollbar {
  scrollbar-color: $color-red $color-black;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-red;
  }

  &::-webkit-scrollbar-track {
    background: $color-black;
  }
}

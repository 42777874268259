@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 587px;
  gap: 16px;
}

.title {
  font-size: 32px;
  line-height: 39px;
  font-weight: 500;
  color: $color-white;
}

.description {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: $color-white;

  .error & {
    color: $color-red;
  }
}

.form {
  display: none;

  &.active {
    display: flex;
    gap: 16px;
  }
}

@include media-width-down($screen-xs) {
  .root {
    width: 100%;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }

  .description {
    font-size: 14px;
    line-height: 17px;
  }
}

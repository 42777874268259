@import 'src/styles/services';

.root {
  color: $color-white;
  height: calc(100% - 38px);
}

.emptyRoot {
  display: grid;
  place-items: center;
}

.table {
  margin-top: 6px;
  overflow-y: auto;
}

.empty {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  place-items: center;
  gap: 16px;
}

.popupWrapper {
  max-width: 444px;
}

@include media-width-down($screen-xs) {
  .emptyButton {
    width: 100%;
  }

  .popupWrapper {
    max-width: 328px;
    width: calc(100% - 30px);
  }
}

@include media-width-down($screen-md) {
  .root {
    margin-top: 10px;
  }
}

@import '../services';

$font-sizes: (
  sm: 16px 20px 12px 15px,
  md: 18px 22px 14px 17px,
  lg: 20px 24px 16px 20px,
  xl: 24px 29px 20px 24px,
);

.button_font-size_none {
  font-size: inherit;
  line-height: inherit;
}

@each $name, $sizes in $font-sizes {
  .button_font-size_#{$name} {
    font-size: nth($sizes, 1);
    line-height: nth($sizes, 2);

    @include media-width-down($screen-xs) {
      font-size: nth($sizes, 3);
      line-height: nth($sizes, 4);
    }
  }
}

/* Size variant */
.button_size_none {
  padding: 0;
}

.button_size_sm {
  padding: 0 15px;
  height: 40px;
}

.button_size_md {
  padding: 0 15px;
  height: 50px;

  @include media-width-down($screen-xs) {
    height: 40px;
  }
}

.button_size_lg {
  padding: 0 24px;
  height: 48px;
}
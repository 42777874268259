@import '../../styles/services';

.root {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  cursor: inherit;
}

.postfix {
  right: 0;
  height: 100%;
}

.button {
  height: 100%;
  width: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $color-grey;
}

.arrowIcon {
  width: 16px;
  height: 6px;
  pointer-events: none;
  z-index: 0;
  transform: rotate(0);
  transition: trs(transform);
}

.disabled {
  color: $color-grey;
}

.icon {
  width: 20px;
  height: 20px;
  pointer-events: none;
  z-index: 0;
  color: $color-grey;
}

.open {
  .input {
    border-color: $color-red;
  }

  &.bottomPlacement {
    .input {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.topPlacement {
    .input {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .arrowIcon {
    transform: rotate(-180deg);
  }
}

.dateInput {
  position: absolute;
  width: 100%;
  min-width: 100%;
  inset: 0;
  opacity: 0;
  margin: 0;
  border: 0;
  padding: 0;
  z-index: 1;

  &::-webkit-inner-spin-button {
    display: none;
  }
}
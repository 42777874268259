@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  padding-bottom: 28px;

  @include media-width-down($screen-xs) {
    padding-bottom: 0;
  }

  .tabs {
    border-bottom-width: 1px;
    border-bottom-color: $color-black;
    grid-template-columns: repeat(var(--children-count), auto) 1fr;

    @include media-width-down($screen-xs) {
      grid-template-columns: repeat(var(--children-count), 1fr);
    }
  }

  .tab {
    &::before {
      height: 3px;
      bottom: 2px;
    }
  }
}

.container {
  max-width: 1092px;
  margin: 0 auto;
}

.content {
  position: relative;
  flex-grow: 1;
  height: calc(100% - 48px);
}

.popup {
  .container {
    max-height: 100vh;
    max-height: 100svh;
  }

  .wrapper {
    width: 100%;
    max-height: 891px;
    max-width: 1032px;
    min-width: 1032px;
    height: 100%;

    .close {
      top: -16px;
      right: -16px;
      clip: rect(0, 40px, 40px, 3px);
    }
  }
}

.containerInWebView {
  padding: 0;

  .wrapper {
    max-height: 100%;
  }
}

@include media-width-down($screen-1032) {
  .popup {
    .wrapper {
      width: 100%;
      min-width: unset;

      .close {
        top: 8px;
        right: 5px;
        width: 26px;
        height: 20px;
      }
    }
  }
}

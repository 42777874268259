@import 'src/styles/services';

.root {
  align-items: center;

  @include hover {
    &.default {
      .text {
        color: $color-white;
      }

      .icon {
        opacity: 1;
      }
    }
  }
}

.box {
  width: 25px;
  height: 25px;
  transition: trs(border-color);
  border-radius: 100%;
  background-color: $color-grey;

  .green &,
  .red &,
  .yellow & {
    background-color: $color-white;
  }
}

.text {
  margin-left: 8px;
  font-size: 16px;
  line-height: 19px;
  user-select: none;
  color: $color-grey-light;
  transition: trs(color);

  .green & {
    color: $color-green;
  }

  .red & {
    color: $color-red;
  }

  .yellow & {
    color: $color-yellow;
  }
}

.icon {
  width: 13px;
  height: 11px;
  transition: trs(color, opacity);
  margin-left: 2px;
  color: $color-white;
  opacity: 0;

  .green & {
    color: $color-green;
  }

  .red & {
    color: $color-red;
  }

  .yellow & {
    color: $color-yellow;
  }
}

.checked {
  .box {
    .icon {
      opacity: 1;
    }
  }
}

.input {
  &:disabled + .box {
    background-color: $color-black-light;

    & .icon,
    & + .text {
      color: $color-grey;
    }
  }
}

.default {
  &.checked {
    .box {
      background-color: $color-white;

      .icon {
        color: $color-red;
      }
    }
  }
}

@include media-width-down($screen-xs) {
  .text {
    margin-left: 10px;
  }
}

@import 'src/styles/services';

.popup {
  .wrapper {
    padding: 0;
    border-radius: 15px;
  }

  .close {
    top: 6px;
    right: 6px;

    svg {
      color: $color-white;
    }
  }
}

@include media-width-down($screen-xs) {
  .popup {
    .wrapper {
      width: fit-content;
      max-width: 100%;
    }

    .popupContainer {
      overflow: inherit;
    }

    .close {
      top: 6px;
      right: 6px;
    }
  }
}

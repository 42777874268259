@import 'src/styles/services';

$gap: 16px;

.root {
  position: relative;
  flex-direction: column;
  gap: $gap;
  width: 100%;
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
}

.socialList {
  width: 72px;
}

.promoCodeField {
  flex: auto;
}

.agreeField {
  flex: auto;
}

// Variants
.defaultVariant {
  .currencyField {
    width: calc(100% - 72px - $gap);
  }

  .promoCodeField {
    width: 203px;
  }

  .agreeField {
    width: calc(100% - 203px - $gap);
  }
}

.bannerVariant {
  .socialList {
    order: -1;
    width: 100%;
  }

  .currencyField,
  .promoCodeField,
  .agreeField {
    width: 100%;
  }
}

@include media-width-down($screen-xs) {
  .defaultVariant {
    .socialList {
      order: -1;
      width: 100%;
    }

    .currencyField,
    .promoCodeField,
    .agreeField {
      width: 100%;
    }
  }
}

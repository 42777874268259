@import 'src/styles/services';

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: $preloader-z-index;
  cursor: default;
  background-color: $color-black;

  &.opened {
    display: flex;
  }

  .container {
    position: relative;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      position: absolute;
      height: 100%;
      width: 100%;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@include media-width-down($screen-xs) {
  .root .container {
    width: 120px;
    height: 120px;
  }
}

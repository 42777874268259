@import 'src/styles/services';

@include media-width-down($screen-xs) {
  .root {
    &.withWarning {
      .content {
        flex-direction: column;
      }

      .form {
        display: block;
        order: -1;
        margin-bottom: 16px;
        overflow: initial;
        width: 100%;
      }

      .methods {
        display: flex;
        flex-grow: 1;
      }
    }
  }
}

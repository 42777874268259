@import 'src/styles/services';

.root {
  position: relative;
  color: $color-white;
}

.title {
  color: #ffa902;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}

.text {
  margin-top: 12px;
  color: $color-white;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: space-between;
  margin-top: 16px;
}

@include media-width-down($screen-xs) {
  .title {
    font-size: 16px;
    line-height: 20px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    max-width: 280px;
  }

  .buttonContainer {
    margin-top: 12px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .button {
    width: 100%;
  }
}

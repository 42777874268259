$font-sizes: (
  xs: 13px,
  sm: 14px,
  md: 15px,
  lg: 16px,
  xl: 18px,
);

$line-heights: (
  xs: 15px,
  sm: 24px,
  md: 24px,
  lg: 24px,
  xl: 24px,
);

$title-sizes: (
  h1: 50px,
  h2: 40px,
  h3: 22px,
);

@mixin text($size: xl, $weight: 400) {
  $font-size: map-get($font-sizes, $size);
  $line-height: map-get($line-heights, $size);

  font-size: $font-size;
  line-height: $line-height;
  font-weight: $weight;
}

@mixin title($size, $weight: 900) {
  $font-size: map-get($title-sizes, $size);
  $line-height: 1.21 * $font-size;

  font-size: $font-size;
  line-height: $line-height;
  font-weight: $weight;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function to-rem($value) {
  $rem-size: 16;

  @return $value / $rem-size;
}

@function clamp-size($min-fz, $max-fz, $min-vw: $screen-xxs, $max-vw: $screen-xl) {
  $min-vw-rem: to-rem(strip-unit($min-vw));
  $max-vw-rem: to-rem(strip-unit($max-vw));
  $min-fz-rem: to-rem(strip-unit($min-fz));
  $max-fz-rem: to-rem(strip-unit($max-fz));

  $slope: ($max-fz-rem - $min-fz-rem) / ($max-vw-rem - $min-vw-rem);
  $y-axis-intersection: -$min-vw-rem * $slope + $min-fz-rem;

  $min: #{$min-fz-rem}rem;
  $max: #{$max-fz-rem}rem;

  @return clamp(#{$min}, #{$y-axis-intersection}rem + #{$slope * 100}vw, #{$max});
}

@import 'src/styles/services';

.root {
  flex: 1;

  :global .form-field__control {
    &::placeholder {
      color: $color-grey-light;
    }
  }
}

.addPromoLabel {
  margin-left: 8px;
}

.button {
  padding: 0 8px;
}

.appliedIconBtn {
  color: $color-green;
}

.appliedField .input {
  border-color: $color-green;
  color: $color-green;
}

.field .applyBtn[disabled] {
  color: $color-grey-2;
}

@import 'src/styles/services';

.root {
  @include text(lg);

  .label {
    top: 0;
    transform: translateY(-50%);
  }

  &:focus-within {
    .label {
      opacity: 1;
    }
  }

  &:not(:focus-within) {
    .label.confirmed {
      color: #75d751;
    }
  }

  .success {
    color: $color-green;

    &:not(:global(.form-field_disabled)) {
      border: 1px solid $color-green;

      &:hover {
        border-color: $color-green; // Явно указываем hover для читаемости
      }
    }
  }

  .successLabel {
    background-color: $color-green;
  }

  .error {
    color: $color-white;

    &:not(:global(.form-field_disabled)) {
      border: 1px solid $color-red;

      &:hover {
        border-color: $color-red;
      }
    }
  }

  .errorLabel {
    background-color: $color-red;
  }
}

.input {
  @include base-field;
}

.textareaField {
  display: flex;
  min-width: 100px;
  width: 100%;
}

.textarea {
  @include base-field;

  padding: 10px 15px 10px 20px;
  resize: none;
  width: 100%;
  border-radius: 5px;

  &::-webkit-scrollbar-thumb {
    background: $color-grey;
  }
}
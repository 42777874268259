@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 587px;
  gap: 16px;

  .header {
    margin: -16px -16px;
    width: calc(100% + 32px);
  }

  .tabs {
    width: calc(100% + 2 * 16px);
    border-bottom: 1px solid $color-black;
    margin: 0 -16px;
    height: 48px;
    grid-template-columns: repeat(var(--children-count), max-content);
    justify-content: center;

    .tab {
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 15px;
    }
  }

  .form {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

.forgotButton {
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 0;

  @include hover {
    color: $color-red;
  }
}

@include media-width-down($screen-xs) {
  .root {
    width: 100%;

    .tabs {
      height: 47px;
    }
  }
}

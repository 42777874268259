@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $color-black;
}

.textWrapper {
  display: flex;
  align-items: center;
  font-weight: 500;
  width: 100%;
}

.text {
  width: 100%;
  word-break: break-all;
  text-align: center;
}

.qrCodeWrapper,
.imageWrapper {
  position: relative;
  padding: 10px;
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: $border-lg;
}

.imageWrapper {
  overflow: hidden;
}

.qrCode {
  width: 100%;
  height: 100%;
}

.copyButton {
  color: $color-red;
  margin-left: 8px;
  width: 27px;
  height: 27px;

  svg {
    width: 100%;
    height: 100%;
  }
}

@import 'src/styles/services';

@mixin placeholder {
  color: $color-white;
  vertical-align: middle;
  opacity: 1;

  @include text(lg);
}

.opened {
  .input {
    border-bottom: none;
    border-radius: $border-sm $border-sm 0 0;
  }

  .icon {
    transform: rotate(-180deg);
  }
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .disabled {
    color: $form-field-disabled-color;
    background-color: $form-field-disabled-bg;
  }

  .button {
    position: absolute;
    height: 100%;
    width: 40px;
    right: 0;
    top: 0;

    .icon {
      width: 16px;
      height: 6px;
      transition: trs(transform);
      color: $color-grey;
    }
  }
}

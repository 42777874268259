@import 'src/styles/services';

.root {
  .wrapper {
    max-width: 328px;
    width: 100%;

    @include media-width-down($screen-xs) {
      max-width: unset;
      width: unset;
    }
  }
}

@import 'src/styles/services';

.codeWrapper {
  position: relative;
  width: 120px;
  aspect-ratio: 1/1;
  margin: 0 auto;
  background-color: $color-white;
  padding: 10px;
  border-radius: $border-lg;
}

.code {
  width: 100%;
  height: 100%;
}

@import 'src/styles/services';

.root {
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 0;

  @include hover {
    color: $color-red;
  }
}

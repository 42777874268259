@import 'src/styles/services';

$close-size: 40px;
$close-size-mob: 30px;

.root {
  z-index: $popup-z-index;

  @include media-width-down($screen-xs) {
    transition: trs(opacity, visibility);
    transform: scale(1);

    &.visible {
      transform: none;
    }
  }
}

.close {
  position: absolute;
  cursor: pointer;
  top: -8px;
  right: -8px;
  z-index: 3;
  width: $close-size;
  height: $close-size;
}

.container {
  padding: 30px 0;

  @include media-width-down($screen-xs) {
    padding: 20px 0;
  }
}

.wrapper {
  padding: 16px;
  max-width: calc(100% - $close-size * 1.5);
  background: $color-black-light;

  @include media-width-down($screen-xs) {
    width: calc(100% - $close-size-mob * 1.5);
    max-width: calc(100% - $close-size-mob * 1.5);
  }
}

@import 'src/styles/services';

.root {
  position: relative;
  width: 100%;
}

.option {
  align-items: center;
  padding: 5px 10px;
  width: 100%;
  text-decoration: none;
  background-color: transparent;
  border: none;
  transition: trs(color);
  color: $color-white;
  cursor: pointer;
  white-space: initial;

  @include text(lg);

  &:focus {
    color: $color-grey-light;
  }

  @include hover {
    color: $color-grey-light;
  }

  + .option {
    margin-top: 5px;
  }
}

.prefix {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 5px;
}

.empty {
  font-size: 10px;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding: 4px;
  text-transform: none;
}

.defaultInputVariant {
  color: $color-white;
}

.lightInputVariant {
  color: $color-black;
}

.dropdownContainer {
  background-color: $color-black-light;
  border-radius: 0 0 $border-sm $border-sm;
  border: 1px solid $color-grey;
  top: 44px;

  :global(.ps__rail-y) {
    background-color: $color-black !important; // stylelint-disable-line declaration-no-important
  }
}

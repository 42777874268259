@import 'src/styles/services';

.root {
  margin-bottom: 16px;
}

.textFieldsWrapper {
  background-color: $color-black;
  border-radius: $border-lg;
  padding: 16px;

  .field + & {
    margin-top: 8px;
  }
}

.field {
  color: $color-white;

  + .field {
    margin-top: 8px;
  }
}

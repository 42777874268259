@import '../services';

@mixin autoComplete {
  transition: background-color 500000s ease 500000s;
  -webkit-box-shadow: 0 0 0 1000px $color-black-light inset;
  -webkit-text-fill-color: $color-white;
  caret-color: $color-white;
}

.form-field__wrapper {
  padding: 4px 12px;
}

.form-field__control {
  transition: trs(background);

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @include autoComplete;
  }
}

.form-field_size_xs {
  font-size: 16px;
}

@include media-width-down($screen-xs) {
  .form-field_size_xs {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
}

@import 'src/styles/services';

.loaderWrapper {
  position: relative;
  width: 844px;
  height: 542px;
  background-color: $color-black;
}

@include media-width-down($screen-xs) {
  .loaderWrapper {
    width: 320px;
    height: 568px;
  }
}

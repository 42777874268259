@import 'src/styles/services';

.root {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: $color-white;
}

.header {
  align-self: flex-start;
  margin-bottom: 25px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

.field {
  + .field {
    margin-top: 20px;
  }
}

@include media-width-down($screen-xs) {
  .header {
    margin-bottom: 20px;
  }

  .content {
    font-size: 14px;
    line-height: 17px;
  }

  .field {
    + .field {
      margin-top: 15px;
    }
  }
}

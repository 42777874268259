@import 'src/styles/services';

.root {
  position: relative;
  width: 100%;
}

.field {
  display: flex;
  gap: 16px;
}

.input {
  font-weight: 900;
}
@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: center;
}

.title {
  font-size: 22px;
  font-weight: 900;
  line-height: 24px;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
}

.list {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.item {
  &:active {
    color: $color-white;
  }

  @include hover {
    color: $color-white;
  }
}

.icon {
  width: 28px;
  height: 28px;
}

@include media-width-down($screen-xs) {
  .root,
  .list {
    gap: 8px;
  }
}

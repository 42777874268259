@import 'src/styles/services';

.root {
  position: relative;
  flex-direction: column;
  width: 100%;
}

.title {
  font-size: 32px;
  line-height: 39px;
  font-weight: 500;
}

.description {
  margin-top: 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 380px;
  border-radius: $border-lg;
  background: $color-black;
  overflow: hidden;
  padding-bottom: 16px;
}

.buttonContainer {
  padding: 16px;
}

.header {
  padding: 16px;
  display: flex;
  justify-content: space-between;

  .button {
    color: $color-white;
    width: 8px;
    height: 8px;
  }
}

.title {
  @include text(xl, 900);

  color: $color-white;
  max-width: calc(100% - 40px);
  overflow: hidden;
}

.icon {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}

.scrollbar {
  position: relative;
  overscroll-behavior: none;
  width: 100%;
}

.line {
  display: flex;
  padding: 8px 16px;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  border-bottom: 1px solid $color-black-light;
  color: $color-white;
  direction: ltr;
}

.lineTitle {
  overflow: hidden;
  word-wrap: break-word;
}

.lineText {
  margin-left: 5px;
  color: #ffa902;
}

.footer {
  flex-direction: column;
  align-items: flex-start;

  .footerTitle {
    @include text(sm, 900);

    color: $color-white;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 16px 0;
  }

  .footerText {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: $color-grey-light;
    padding: 0 16px;
  }

  .buttonFooter {
    max-width: 90%;
    margin: 16px auto;
  }
}

@include media-width-down($screen-xs) {
  .root {
    width: 360px;
    border-radius: $border-md;
  }

  .title {
    max-width: calc(100% - 35px);
  }

  .footer {
    .lineText {
      margin: 4px 0 0;
    }
  }

  .header {
    .button {
      color: white;
      width: 10px;
      height: 10px;
    }
  }
}

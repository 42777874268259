@import 'src/styles/services';

.tab {
  position: relative;
  padding: 15px;
  font-weight: bold;
  text-transform: uppercase;

  &:before {
    bottom: 0;
  }

  &.active {
    color: $color-red;

    @include hover {
      color: $color-red;
    }
  }
}

@import 'src/styles/services';

.form {
  position: relative;
}

.backButton {
  display: none;

  .backIcon {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
}

.useBonus {
  @include text(md, 900);

  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-white;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.slider {
  margin-bottom: 16px;
}

.field {
  + .field {
    margin-top: 8px;
  }
}

.footer {
  color: $color-grey-light;

  .value {
    color: $color-red;
  }
}

.footerData {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-bottom: 8px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

@include media-width-down($screen-1032) {
  .backButton {
    display: flex;
    margin-bottom: 16px;
  }
}

@import 'src/styles/services';

$gap: 16px;

.root {
  position: relative;
  flex-direction: column;
  gap: $gap;
  width: 100%;
}

.mailIcon {
  color: $color-grey;

  &.hasError {
    color: $color-red;
  }
}

.promoCodeField {
  flex: auto;
}

.currencyField {
  input {
    min-width: 100%;
  }
}

.agreeField {
  flex: auto;
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
}

.buttonContainer {
  display: flex;
  gap: $gap;
}

// Variants
.defaultVariant {
  .emailField {
    width: calc(50% - $gap / 2);
  }

  .passwordField {
    width: calc(50% - $gap / 2);
  }

  .currencyField {
    width: 90px;
  }

  .countryField {
    width: calc(100% - 90px - $gap);
  }

  .promoCodeField {
    width: 203px;
  }

  .agreeField {
    width: calc(100% - 203px - $gap);
  }
}

.bannerVariant {
  .emailField {
    width: calc(50% - $gap / 2);
  }

  .passwordField {
    width: calc(50% - $gap / 2);
  }

  .currencyField {
    width: calc(50% - $gap / 2);
  }

  .countryField {
    width: calc(50% - $gap / 2);
  }

  .promoCodeField {
    width: 100%;
  }

  .agreeField {
    width: 100%;
  }
}

@include media-width-down($screen-xs) {
  // Variants
  .defaultVariant {
    .emailField {
      width: 100%;
    }

    .passwordField {
      width: 100%;
    }

    .currencyField {
      width: 90px;
    }

    .buttonContainer {
      flex-direction: column;
    }

    .countryField {
      width: calc(100% - 90px - $gap);
    }

    .promoCodeField {
      width: 100%;
    }

    .agreeField {
      width: 100%;
    }
  }
}

@import 'src/styles/services';

.root {
  flex: 1;
}

.text {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: $color-grey-light;
}

.box {
  width: 20px;
  height: 20px;
}

.link {
  text-decoration: underline;
}

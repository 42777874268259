@import 'src/styles/services';

.root {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $color-black-light;
  z-index: 1;
  flex-grow: 1;

  :global(.tippy-content) {
    padding: 0;
    width: 100%;
  }

  .tabContainer {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      height: 1px;
      background-color: $color-black;
    }
  }

  .scrollbar {
    position: relative;
    display: flex;
    justify-content: center;

    @include media-width-down($screen-xs) {
      justify-content: flex-start;
      padding: 0 16px;
    }
  }

  .tabsWrapper {
    position: relative;

    :global(.ps__rail-x) {
      display: none;
    }
  }

  .tabs {
    gap: 16px;
    margin-inline: auto;
  }

  .tab {
    overflow: initial;
    padding: 0 0 15px;
    font-size: 15px;
    font-weight: 900;
  }

  // Variants
  &.defaultVariant {
    .tabsWrapper,
    .tabs {
      height: 55px;

      @include media-width-down($screen-xs) {
        height: 47px;
      }
    }

    .tab {
      padding-top: 23px;

      @include media-width-down($screen-xs) {
        padding-top: 15px;
      }
    }
  }

  &.bannerVariant {
    .tabsWrapper,
    .tabs {
      height: 47px;
    }

    .tab {
      padding-top: 15px;
    }
  }
}

.form {
  display: flex;
}

// Variants
.defaultVariant {
  .form {
    padding: 23px 24px 24px 24px;

    @include media-width-down($screen-xs) {
      padding: 24px 16px 16px;
    }
  }
}

.bannerVariant {
  .form {
    padding: 23px 16px 16px 16px;
  }
}

.buttonWithoutAccount {
  margin-top: 30px;
}

@include media-width-down($screen-sm) {
  .root {
    width: 100%;
  }
}

@include media-width-down($screen-xs) {
  .buttonWithoutAccount {
    margin-top: 20px;
  }
}

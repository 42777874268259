@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 82px;
  background-image: url('/assets/register/images/background.webp');
}

.medalImg {
  position: absolute;
  width: 138px;
  top: -58px;
  left: -54px;
  z-index: 2;
  pointer-events: none;
}

.text {
  display: flex;
  flex-direction: column;
  width: 368px;
  height: 100%;
  padding: 16px 0;
  text-transform: uppercase;
  color: $color-white;

  .bonus {
    margin-bottom: 2px;
    text-align: end;
    font-weight: 900;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-shadow: 0 4px 16px rgba(255, 255, 255, 0.65);
    -webkit-text-stroke: 1px $color-red;
  }

  .deposit {
    background: $color-yellow;
    padding: 4px 8px;
    font-size: 13px;
    line-height: 15.7px;
    font-weight: 700;
    text-align: end;
  }
}

.groupImg {
  position: absolute;
  width: 307px;
  top: -109px;
  right: 7px;
  pointer-events: none;
  z-index: 2;
}

@include media-width-down($screen-xs) {
  .root {
    height: 66px;
  }

  .text {
    width: 100%;
    margin: 0;
    padding: 8px 0;

    .bonus {
      font-size: 18px;
      width: 100%;
      text-align: center;
    }

    .deposit {
      width: 100%;
      text-align: center;
    }
  }
}

// Variants
.registerVariant {
  @include media-width-down($screen-xs) {
    .medalImg {
      width: 90px;
      top: -23px;
      left: -40px;
    }

    .groupImg {
      width: 50px;
      top: 15px;
      right: -23px;
    }
  }
}

.loginVariant {
  @include media-width-down($screen-xs) {
    .medalImg {
      width: 105px;
      height: 136px;
      top: -44px;
      left: -44px;
    }

    .groupImg {
      width: 170px;
      height: 140px;
      top: -37px;
      right: 45px;
    }
  }
}

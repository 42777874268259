.accordion {
  border: none;
}

.accordion__control {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  font-weight: 900;
  font-size: 16px;
  line-height: 100%;
  color: $color-white;
  cursor: pointer;

  &[aria-expanded='true'] {
    color: $color-red;
  }
}

.accordion__chevron {
  color: $color-white;
  width: 10px;
  height: 6px;
  flex-shrink: 0;
  transform: rotate(0);
  transition: trs(transform);

  &[data-rotate] {
    transform: rotate(180deg);
  }
}

.accordion__content {
  padding: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  border-top: 1px solid $color-black;
  background-color: $color-black-light;

  a {
    color: $color-white;
  }
}
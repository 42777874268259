@import 'src/styles/services';

.root {
  width: 100%;
  text-align: center;
}

.description {
  margin-bottom: 16px;
  text-align: center;
  text-transform: uppercase;
  color: $color-yellow;

  @include title(h3);
}

.button {
  + .button {
    margin-top: 16px;
  }
}

@include media-width-down($screen-xs) {
  .description {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }

  .button {
    + .button {
      margin-top: 10px;
    }
  }
}

@import 'src/styles/services';

.root {
  height: 100%;
  display: flex;

  .wrapper {
    transition: trs(color);

    :global {
      .form-field__control {
        letter-spacing: 1px;
      }
    }
  }

  .empty .wrapper:not(:global(.form-field_focused)) {
    color: $color-grey;
    font-weight: unset;
  }
}

.select {
  min-width: 132px;
  max-width: 132px;
}

@import 'src/styles/services';

.root {
  overscroll-behavior: none;

  :global {
    .ps__rail-y {
      /* stylelint-disable declaration-no-important */
      display: block !important;
      left: auto !important;
      right: 0 !important;
      opacity: 1 !important;
      width: 4px !important;
      background-color: $color-black-light !important;
      /* stylelint-enable declaration-no-important */
    }

    .ps__thumb-y {
      /* stylelint-disable declaration-no-important */
      right: 0 !important;
      width: 4px !important;
      background-color: $color-red !important;
      /* stylelint-enable declaration-no-important */
    }
  }
}

@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 78px;
  height: 80px;
  clip-path: polygon(0 15%, 62% 6%, 132% 49%, 67% 5%, 101% 0, 102% 25%, 105% 100%, 5% 91%);

  .defaultVariant & {
    background-color: $color-yellow;
  }

  .successVariant & {
    background-color: $color-green;
  }

  .errorVariant & {
    background-color: $color-red;
  }
}

.icon {
  color: $color-white;
}

.default {
  font-size: 30px;
}

.title {
  text-align: center;
  color: $color-yellow;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.text {
  text-align: center;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
}

.whiteTextColor {
  color: $color-white;
}

.greyTextColor {
  color: $color-grey;
}

.greyLightTextColor {
  color: $color-grey-light;
}

.yellowTextColor {
  color: $color-yellow;
}

.smallTextSize {
  font-size: 16px;
  line-height: 24px;
}

.mediumTextSize {
  font-size: 18px;
  line-height: 24px;
}

.largeTextSize {
  font-size: 22px;
  line-height: 28px;
}

@import 'src/styles/services';

.label {
  position: absolute;
  left: 8px;
  max-width: calc(100% - 16px);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background: $color-grey;
  border-radius: 5px;
  padding: 0 8px;
  color: $color-black;
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 1;

  &.hasError {
    opacity: 1;
    color: $color-black;
    background-color: $color-red;
  }

  &.hasOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.hasValue {
    opacity: 1;
  }

  &.hasApprove {
    color: $color-green;
  }
}

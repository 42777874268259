@import 'src/styles/services';

.group {
  display: flex;
  gap: 10px;
}

.field {
  flex: 1 1 auto;
}

.error {
  @include text(xs);

  margin-top: 4px;
  color: $color-red;
}

.caption {
  @include text(sm, 900);

  color: $color-black;
  text-transform: uppercase;

  &.above {
    margin-bottom: 8px;
  }

  &.below {
    margin-top: 8px;
  }
}

@import 'src/styles/services';

.root {
  &.text {
    @include text(xs);

    height: auto;
  }
}

.timer {
  color: #b7b4bc;
  margin-top: 16px;
  display: inline-block;
  width: 100%;
  text-align: center;
}

@import 'src/styles/services';

.root {
  color: $color-black;
  margin-bottom: 16px;

  .backButton {
    display: none;

    .backIcon {
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      color: $color-white;
    }
  }
}

.wrapper {
  background-color: #f0f4fc;
  border-radius: 0 0 $border-lg $border-lg;
  padding: 16px 20px 20px;
}

.description {
  color: $color-black;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 1.4;
}

.head {
  background-color: $color-black;
  padding: 18px 20px;
  color: $color-white;
  font-size: 15px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border-radius: $border-lg $border-lg 0 0;
}

.link {
  color: $color-black;
  text-decoration: underline;
  font-size: 18px;
  line-height: 1.4;
  vertical-align: baseline;
}

.title {
  @include title(h3);

  text-transform: uppercase;
  color: $color-black;
  margin-bottom: 8px;
}

.button {
  width: 100%;
}

@include media-width-down($screen-1032) {
  .root {
    .backButton {
      display: flex;
      margin-bottom: 16px;
    }
  }
}

@import 'src/styles/services';

@mixin placeholder {
  color: $color-white;
  font-size: 18px;
  font-weight: 400;
  vertical-align: middle;
  opacity: 1;

  @include media-width-down($screen-xs) {
    font-size: 14px;
    line-height: 17px;
  }
}

.root {
  width: 100%;
  position: relative;
  display: flex;
  line-height: 22px;
  cursor: pointer;
  white-space: initial;
  outline: none;
  background: transparent;
  padding: 4px 15px 4px 12px;
  border: 1px solid $color-grey;
  border-radius: 5px;
  align-items: center;

  &.disabled {
    cursor: default;
    color: $color-grey;

    &.safariMobile {
      // mobile safari lowers opacity of disabled input to 0.4
      // we want to keep opacity of disabled native select save too
      border-color: rgba($color-grey, 0.4);
      color: rgba($color-grey, 0.4);
    }
  }
}

.placeholder,
.current {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.select {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.label {
  position: absolute;
  top: 0;
  left: 8px;
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background: $color-grey;
  border-radius: 5px;
  padding: 0 8px;
  transform: translateY(-50%);
  color: $color-black;
  transition: all 0.2s ease;
  z-index: 1;
}

.icon {
  width: 10px;
  height: 6px;
  margin-left: 12px;
  color: $color-grey;
  transition: trs(transform, color);

  &.iconDisabled {
    display: none;
  }

  @include media-width-down($screen-xs) {
    margin-left: 6px;
  }
}

.selected {
  margin-left: 10px;
}

.sizeXs {
  height: $form-field-size-xs;
}

.sizeMd {
  height: $form-field-size-md;
}

@import 'src/styles/services';

.root {
  border-top: 1px solid $color-black;
  padding: 16px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media-width-down($screen-xs) {
    padding: 16px;
  }
}

.refuseContainer {
  display: flex;
  justify-content: space-between;

  .label {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 5%;
    font-weight: 900;
    color: $color-white;
  }
}

.bonusesWrapper {
  @include media-width-down($screen-xs) {
    overflow-x: auto;
    margin-right: -16px;
  }

  .bonusesContainer {
    display: flex;
    gap: 16px;

    @include media-width-down($screen-xs) {
      padding-right: 16px;
      width: fit-content;
    }
  }
}

@import 'src/styles/services';

.root {
  .input {
    color: $color-black;
  }
}

.amountPostfix {
  text-transform: uppercase;
  color: $color-grey-light;
  font-size: 16px;
}

@import 'src/styles/services';

$icon-size: 20px;

.root {
  --icon-size: #{$icon-size};

  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  transition: trs(border);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $color-black-light;
  border-radius: $border-lg;
  cursor: pointer;
  padding: 0;
  min-height: 321px;
}

.lockedBg,
.lockedBorder {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 10px;
}

.lockedBg {
  z-index: 3;
  background-color: rgba(172, 172, 172, 0.5);
}

.lockedBorder {
  border: 1px solid $color-red;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 0 16px;

  .info {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    width: $icon-size;
    height: $icon-size;
    z-index: 3;
    border: none;
  }

  .infoIcon {
    width: $icon-size;
    height: $icon-size;
  }
}

.image {
  position: relative;
  margin: 24px 0 32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  aspect-ratio: 278/152;
  min-height: 152px;
}

.tooltip {
  height: auto;
}

.footer {
  position: relative;
  padding: 16px 0;

  .button {
    margin: 16px 0;
    height: 48px;
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 1.1px;
    text-transform: uppercase;
  }
}

.depositNumber {
  font-weight: 900;
  font-size: 10px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-grey-light;
  border: 1px dashed $color-grey;
  border-radius: $border-sm;
}

.activeMessage {
  display: none;
}

@include media-width-down($screen-lg) {
  .depositNumber {
    font-size: 14px;
    line-height: 17px;
  }
}

@include media-width-down($screen-xs) {
  .root {
    min-height: 321px;
  }

  .depositNumber {
    font-weight: 400;
    text-transform: none;
    font-size: 10px;
  }

  .image {
    aspect-ratio: 168/87;
    min-height: 87px;
  }
}

// Active Variant
.supportActive {
  cursor: pointer;
  border: 1px solid transparent;

  &.active:not(.locked) {
    border: 1px solid $color-red;
  }
}

// Disable variant
.disabled {
  filter: grayscale(100%);

  .footer {
    .button {
      color: $color-white;
      background-color: $color-red;
    }
  }
}

// Size Variants
.mediumSize {
  width: 268px;
  min-height: 96px;
  background-image: url('/assets/bonusPacket/images/waterBg.webp');
  background-size: cover;
  background-color: $color-black;
  padding: 16px;

  .content {
    flex-direction: row;
    padding: 0;
  }

  .image {
    width: 69px;
    height: 64px;
    margin: 0 12px 0 0;
    min-height: unset;
    aspect-ratio: 69/64;

    img {
      object-fit: contain;
    }
  }

  &:not(.locked) {
    .activeMessage {
      @include text(xs);

      display: block;
      position: absolute;
      bottom: -18px;
      right: -16px;
      border-radius: 12px 0 8px 0;
      padding: 2px 10px;
      background-color: $color-red;
      color: $color-white;
    }
  }

  .info {
    top: 0;
    right: 0;
    color: $color-grey;
  }

  .footer {
    display: none;
  }

  &.supportActive {
    &.active:not(.locked) {
      background: url('/assets/bonusPacket/images/waterBg.webp'),
        radial-gradient(50% 50% at 50% 100%, rgba(255, 0, 0, 0.3) 0%, rgba(255, 0, 0, 0) 80%), $color-black;
    }
  }
}

.largeSize {
  background-image: url('/assets/bonusPacket/images/defaultBg.webp');

  .depositNumber {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.5px;
  }

  .footer {
    margin-top: auto;
  }
}

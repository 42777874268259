@import 'src/styles/services';

.root {
  position: relative;
  width: 100%;
  z-index: 1;

  .slider {
    margin-left: 0;
  }

  [data-card-info] {
    width: 100%;
  }
}

.infoContainer {
  width: 268px;

  .closeButton {
    width: 20px;
    height: 20px;
  }

  .closeIcon {
    width: 10px;
    height: 10px;
  }

  .infoScrollbar {
    width: calc(100% - 8px);
    padding: 0 8px 0 16px;
  }

  .infoLine {
    padding-left: 0;
    padding-right: 0;
  }
}

.slide {
  flex-shrink: initial;
}

.buttonLeft {
  background: linear-gradient(90deg, $color-black-light 0%, rgba(41, 44, 51, 0) 100%);
}

.buttonRight {
  background: linear-gradient(270deg, $color-black-light 0%, rgba(41, 44, 51, 0) 100%);
}

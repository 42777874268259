@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.header {
  position: relative;
  cursor: pointer;
  color: $color-white;
  height: 100%;
}

.title {
  font-size: 15px;
  line-height: 100%;
  font-weight: 900;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  user-select: none;
  transition: trs(color);
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;

  @include hover {
    .title {
      color: $color-red-light;
    }

    .icon {
      color: $color-red-light;
    }
  }
}

.icon {
  width: 10px;
  height: 6px;
  margin-left: 12px;
  color: $color-grey;
  transition: trs(transform, color);

  @include media-width-down($screen-xs) {
    margin-left: 6px;
  }
}

// Variants
.fulfilledVariant {
  .headerContent {
    background: $color-black-light;
  }

  .icon {
    color: $color-white;
  }
}

.outlinedVariant {
  .header {
    color: $color-grey-light;
  }

  .headerContent {
    height: 100%;
    position: relative;
    padding: 0;
    border-radius: 0;

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 100%;
      top: 100%;
      left: 0;
      transform: translateY(-50%);
      pointer-events: none;
      background-color: $color-red;
      display: none;
      box-shadow: 0 -2px 10px $color-red;
    }
  }

  & [aria-expanded='true'] {
    .headerContent::after {
      display: flex;
    }
  }
}

.root [aria-expanded='true'] {
  .icon {
    transform: rotate(-180deg);
    color: $color-grey;
  }

  &.outlinedVariant {
    .icon {
      color: $color-red;
    }
  }

  .headerContent {
    border-radius: 10px 10px 0 0;
  }

  .title {
    color: $color-red;
  }
}

.container {
  position: relative;
  padding: 8px;
  width: auto;
  font-size: 18px;
  line-height: 22px;
  border-top: 1px solid $color-black;
  border-radius: 10px;
  background-color: $color-grey-dark;
  user-select: none;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: $tippy-z-index;

  &.fullContentWidth {
    width: 100%;
  }
}

.content {
  position: relative;
  width: 100%;
  overscroll-behavior: none;
  color: $color-white;
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: $color-grey;
}

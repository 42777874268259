@import 'src/styles/services';

.root {
  color: $color-white;

  .copyButton {
    margin-left: 8px;
    width: 27px;
    height: 27px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.address {
  word-wrap: break-word;
  text-align: center;
  max-width: calc(100% - 30px);
}

.content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgWrapper {
  position: relative;
  margin-bottom: 15px;
  width: 120px;
  height: 120px;
}

.qrCode {
  width: 100%;
  height: 100%;
  border-radius: $border-sm;
}

.tooltip {
  width: 100%;
}

.tooltipContent {
  color: $color-white;
}

.codeContainer {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 500;
  justify-content: center;
  max-width: 100%;
}

.text {
  margin-bottom: 15px;
}

@import 'src/styles/services';

.root {
  .button {
    position: absolute;
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 2;
    cursor: pointer;

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }

    &.disabled {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}

.endShadow {
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 90px;
    top: 0;
    bottom: 0;
    background: linear-gradient(270deg, $color-black-light 0%, rgba($color-black-light, 0) 100%);
    pointer-events: none;
    z-index: $slider-shadow-z-index;
  }

  &[data-slider-state='end']:after {
    content: none;
  }
}

.startShadow {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 90px;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, $color-black-light 0%, rgba($color-black-light, 0) 100%);
    pointer-events: none;
    z-index: $slider-shadow-z-index;
  }

  &[data-slider-state='start']:before {
    content: none;
  }
}

@import 'src/styles/services';

.root {
  position: relative;
}

.popup {
  &.popupWithWheel {
    z-index: $auth-with-wheel-z-index;
  }

  .wrapper {
    padding: 0;
    width: 656px;
  }

  .container {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .close {
    top: -22px;
    right: -15px
  }

  @include media-width-down($screen-sm) {
    .wrapper {
      width: 100%;
    }

    .container {
      padding: 50px 0 25px;
    }
  }
}

@import 'src/styles/services';

.root {
  width: 100%;
  height: 100%;
  padding-top: 16px;

  :global {
    .ps__rail-x {
      top: 0
    }
  }

  &:hover {
    :global {
      .ps__rail-x {
        opacity: 1;
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  * {
    text-align: start;
  }
}

.header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  th {
    padding-bottom: 10px;
  }
}

.amountData {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.row {
  .colStatus {
    color: $color-yellow;

    &.completed {
      color: $color-green;
    }

    &.rejected {
      color: $color-red;
    }
  }
}

.body {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  td {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $color-grey-2;
  }

  .colActions {
    --padding-right: 16px;

    width: calc(30px + var(--padding-right));
    max-width: calc(30px + var(--padding-right));
    padding: 0;
    padding-right: var(--padding-right);

    .removeIcon {
      height: 13px;
      width: 13px;
    }
  }
}

.showMoreBtn {
  margin-top: 16px;
  width: 200px;
}

@include media-width-down($screen-md) {
  .table {
    width: 100%;
    min-width: 1200px;
  }

  .topScrollbar {
    height: 4px;
    overflow-x: auto;
    overflow-y: hidden;

    & div {
      height: 4px;
      width: 100%;
      min-width: 1200px;
    }
  }
}

@include media-width-down($screen-xs) {
  .header {
    font-size: 12px;
    line-height: 15px;

    th {
      padding-bottom: 0;
    }
  }

  .body {
    .colActions {
      .actionBtn {
        width: 25px;
        height: 25px;
      }

      .removeIcon {
        width: 11px;
        height: 11px;
      }
    }
  }

  .showMoreBtn {
    width: 100%;
  }
}

@import 'src/styles/services';

$half: calc(50% - 10px);

.root {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-top: -20px;
}

.signupButton {
  margin-top: 20px;
}

.select {
  height: 50px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;

  &.column {
    padding-top: 0;
  }

  .column {
    width: $half;
  }

  .columnWithError {
    height: 75px;

    & > div {
      height: 50px;
    }
  }

  &.rowWithError {
    height: 75px;

    & > div {
      height: 50px;
    }
  }
}

.currency {
  height: 50px;
  min-width: 157px;
}

.message {
  margin-bottom: 15px;
}

.datePicker {
  min-width: unset;
  width: 100%;

  input {
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    background-color: inherit;
    border-bottom: 1px solid #2af43e;
    border-right: none;
    width: 100%;
    height: 50px;
  }

  input::placeholder {
    color: #fff;
  }
}

.datePickerError {
  border-bottom: 1px solid #ff0000;
}

.datePickerModal {
  width: 50%;
}

@include media-width-down($screen-sm) {
  .row {
    flex-direction: column;

    .column {
      width: 100%;

      + .column {
        margin-top: 20px;
      }
    }

    .columnWithError {
      margin-top: 20px;
    }

    &.rowWithError {
      height: 150px;

      & > div {
        height: 75px;

        & > label {
          height: auto;
        }
      }
    }
  }

  .datePicker {
    min-width: unset;
    width: 100%;
  }
}

@include media-width-down($screen-xs) {
  .datePicker {
    input {
      height: 40px;
    }
  }

  .row {
    padding-top: 0;

    .column {
      margin-top: 10px;

      + .column {
        margin-top: 10px;
      }

      input {
        height: 40px;
      }
    }
  }

  .select {
    height: 40px;
  }
}

// Variants
.bannerVariant {
  .buttonContainer {
    flex-direction: row;
  }
}

.errorMessage {
  margin-top: 8px;
  color: red;
  text-align: right;
}

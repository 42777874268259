@import 'src/styles/services';

.wrapper {
  max-width: 460px;
}

@include media-width-down($screen-xs) {
  .wrapper {
    max-width: 328px;
  }
}

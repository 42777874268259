@import 'src/styles/services';

.root {
  @include text();

  .button {
    min-width: 186px;
  }
}

.title {
  @include title(h3);

  text-transform: uppercase;
  color: $color-yellow;
  margin-bottom: 8px;
}

.subtitle {
  color: $color-white;
  margin-bottom: 16px;
}

.description {
  color: $color-grey-light;
}

.email {
  color: $color-white;
  margin-bottom: 16px;
}

.emailAddress {
  color: $color-yellow;
}

@include media-width-down($screen-xs) {
  .root {
    text-align: center;
  }

  .button {
    width: 100%;
  }
}

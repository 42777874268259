@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url('#{$path}.#{$extmod}') format('#{$format}'));
  }

  @font-face {
    font-family: '#{$name}';
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: $src;
  }
}

// Regular
@include font-face('Inter', '~/public/fonts/inter/Inter-Regular', 400, normal, 'woff2');

// Medium
@include font-face('Inter', '~/public/fonts/inter/Inter-Medium', 500, normal, 'woff2');

// Bold
@include font-face('Inter', '~/public/fonts/inter/Inter-Bold', 700, normal, 'woff2');
// -----------

// Black
@include font-face('Inter', '~/public/fonts/inter/Inter-Black', 900, normal, 'woff2');
// -----------

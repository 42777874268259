@import 'src/styles/services';

.root:global(.ps) {
  :global {
    .ps__rail-y {
      &,
      &.ps--clicking,
      &:hover {
        /* stylelint-disable declaration-no-important */
        left: auto !important;
        /* stylelint-enable declaration-no-important */
        width: 4px;
        background-color: transparent;
        background-image: linear-gradient($color-grey-2, $color-grey-2);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1px 100%;

        .ps__thumb-y {
          right: 0;
          width: 4px;
          background-color: $color-red;
        }
      }
    }

    .ps__rail-x {
      &,
      &.ps--clicking,
      &:hover {
        height: 4px;
        background-color: transparent;
        background-image: linear-gradient($color-grey-2, $color-grey-2);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 1px;

        .ps__thumb-x {
          bottom: 0;
          height: 4px;
          background-color: $color-red;
        }
      }
    }
  }

  &:hover {
    :global(.ps__rail-y) {
      opacity: 1;
    }
  }
}

.mobile {
  overflow: auto;

  &.smallVerticalSpace {
    padding-right: 5px;
  }

  &.mediumVerticalSpace {
    padding-right: 13px;
  }
}

.scrollWrapper {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }

  &.smallWrapperVerticalSpace.showWrapperVerticalScrollbar {
    margin-right: -8px;
  }

  &.mediumWrapperVerticalSpace.showWrapperVerticalScrollbar {
    margin-right: -16px;
  }

  .smallVerticalSpace {
    padding-right: 8px;

    &.hideVerticalScrollbar {
      padding-right: 0;
    }
  }

  .mediumVerticalSpace {
    padding-right: 16px;

    &.hideVerticalScrollbar {
      padding-right: 0;
    }
  }
}

.vEndShadow {
  &.dark10Shadow:after {
    @include scrollbar-shadow($color-black-lighter);
  }

  &.dark80Shadow:after {
    @include scrollbar-shadow($color-black-light);
  }

  &.dark100Shadow:after {
    @include scrollbar-shadow($color-black);
  }

  &.vReachEnd:after {
    opacity: 0;
  }
}

.hEndShadow {
  &:after {
    opacity: 0;
  }

  &.dark10Shadow:after {
    @include scrollbar-shadow($color-black-lighter, $direction: 'horizontal');
  }

  &.dark80Shadow:after {
    @include scrollbar-shadow($color-black-light, $direction: 'horizontal');
  }

  &.dark100Shadow:after {
    @include scrollbar-shadow($color-black, $direction: 'horizontal');
  }

  &.hReachEnd:after {
    opacity: 1;
  }
}

@import 'src/styles/services';

.root {
  position: relative;
}

.title {
  position: relative;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-yellow;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.6);
  z-index: 3;
}

.stepTitle {
  font-weight: 500;
  position: relative;
  text-align: center;
  margin-top: -35px;
}

.stepDescription {
  position: relative;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 24px;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  z-index: 1;
  border-radius: $border-sm;

  @include media-width-down($screen-xs) {
    width: 100%;
  }
}

.loader {
  position: relative;
  min-height: 295px;
}

@include media-width-down($screen-sm) {
  .title {
    font-size: 50px;
    line-height: 64px;
  }

  .stepTitle {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    margin-top: 0;
  }

  .stepDescription {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
  }
}

@include media-width-down($screen-xs) {
  .title {
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
  }

  .stepTitle {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }

  .stepDescription {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}

@import 'src/styles/services';

.root {
  .backButton {
    display: none;

    .backIcon {
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      color: $color-white;
    }
  }

  .headForm {
    background-color: $color-black;
    padding: 18px 20px;
    color: $color-white;
    font-size: 15px;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    border-radius: $border-lg $border-lg 0 0;
  }
}

.field {
  + .field {
    margin-top: 8px;
  }
}

.staticData {
  color: $color-grey-light;

  &.above {
    margin-top: 8px;
  }

  .value {
    color: $color-red;
  }
}

.withHead {
  border-radius: 0 0 $border-lg $border-lg;
}

@include media-width-down($screen-xs) {
  .root {
    .backButton {
      display: flex;
      margin-bottom: 16px;
    }
  }
}

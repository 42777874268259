@import 'src/styles/services';

.root {
  position: relative;
  width: 100%;
}

.inner {
  display: block;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  padding: 8px;
  background: $color-black;
  border-radius: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $color-white;

  &.hasError {
    background-color: $color-red;
  }
}

.errorIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.rootError {
  :global(.tippy-arrow) {
    color: $color-red;

    &[data-hide] {
      display: none;
    }
  }
}

@import 'src/styles/services';

.wrapper {
  max-width: 380px;
  width: calc(100% - 30px);
}

.title {
  @include text(md, 900);

  text-transform: uppercase;
  padding: 0 20px 0 0;
  color: $color-white;
  margin-bottom: 16px;
}

.content {
  &.hidden {
    display: none;
  }
}

.fields {
  @include text(xs);

  color: $color-white;
  background: $color-black;
  padding: 16px;
  border-radius: $border-lg;
}

.field {
  display: flex;
  align-items: center;

  & + .field {
    margin-top: 8px;
  }
}

.fieldValue {
  color: $color-yellow;
}

@import 'src/styles/services';

.icon {
  color: $color-grey;

  &.hasError {
    color: $color-red;
  }
}

.unsecure {
  .button .icon {
    color: $color-white;
  }
}

.field [type='password']::placeholder {
  letter-spacing: normal !important; // stylelint-disable-line declaration-no-important
}

.field {
  :global {
    .form-field__wrapper {
      background-clip: content-box;
    }

    .form-field__control {
      &:autofill,
      &:-webkit-autofill-strong-password,
      &:-webkit-autofill-strong-password-viewable,
      &:-webkit-autofill-and-obscured {
        -webkit-text-fill-color: $color-white;
        transition: background-color 500000s ease 500000s;
        caret-color: $color-white;
      }
    }
  }
}

@include media-width-down($screen-xs) {
  .icon {
    width: 20px;
    height: 20px;
  }
}

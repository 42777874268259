@import 'src/styles/services';

.root {
  position: relative;
  flex-direction: column;
  width: 587px;
}

@include media-width-down($screen-xs) {
  .root {
    width: 100%;
  }
}

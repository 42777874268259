@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .backIcon {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
}

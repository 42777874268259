@import 'src/styles/services';

.root {
  color: $color-white;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 78px;
  height: 80px;
  clip-path: polygon(0 15%, 62% 6%, 132% 49%, 67% 5%, 101% 0, 102% 25%, 105% 100%, 5% 91%);
  background-color: $color-red;
}

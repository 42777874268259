@import '~swiper/scss';
@import '~swiper/scss/free-mode';
@import '~swiper/scss/mousewheel';
@import '~swiper/scss/scrollbar';
@import 'normalize.css';
@import '~@mwl/ui/dist/styles.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import './helpers/fonts';
@import './helpers/variables';
@import './variables/components';
@import './components';

:root {
  color-scheme: light only;

  /* stylelint-disable */
  --full-height: calc(100vh); // если не поддерживается строчка ниже
  --full-height: calc((var(--vh, 1vh) * 100)); // для мобильных устройств
  /* stylelint-enable */

  --sidebar-z-index: #{$sidebar-z-index};
}

html,
body {
  min-height: var(--full-height);
  height: -webkit-fill-available;
  background-color: $color-black;
}

body {
  min-height: var(--full-height);
  height: -webkit-fill-available;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  overflow-y: scroll;
}

#__next {
  min-height: var(--full-height);
}

* {
  scrollbar-width: thin;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  border: none;
  padding: 0;
  appearance: none;
  background: transparent;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.visually-hidden {
  /* stylelint-disable declaration-no-important */
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  width: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  border: 0 !important;
  /* stylelint-enable declaration-no-important */
}

.tippy-box {
  background-color: unset !important; // stylelint-disable-line declaration-no-important
}

.tippy-content {
  padding: 0 !important; // stylelint-disable-line declaration-no-important
}

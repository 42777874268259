@import 'src/styles/services';

.root {
  position: relative;
  width: 100%;
}

.mailIcon {
  color: $color-grey;
}

.input {
  font-weight: 900;
}
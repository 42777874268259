@import 'src/styles/services';

$half: calc(50% - 10px);

.root {
  position: relative;
  width: 100%;
  z-index: 1;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  + .row {
    padding-top: 20px;
  }

  .column {
    width: $half;
  }
}

.message {
  margin-bottom: 25px;
}

.checkbox {
  margin-left: 20px;
  min-width: $half;
}

.checkboxBox {
  background: #2af43e;

  svg {
    color: transparent;
  }
}

.checkboxLink {
  color: $color-yellow;
  text-decoration: none;
}

.checkboxAll {
  text-transform: uppercase;

  div {
    font-weight: 700;
  }
}

.buttonContainer {
  width: 75%;
  margin: auto;
  margin-top: 30px;
}

@include media-width-down($screen-sm) {
  .row {
    flex-direction: column;

    .column {
      width: 100%;

      + .column {
        margin-top: 20px;
      }
    }

    .checkbox {
      margin-top: 20px;
      margin-left: 0;
      width: 100%;
      order: 3;
    }
  }
}

@include media-width-down($screen-xs) {
  .row {
    + .row {
      padding-top: 10px;
    }

    .column {
      + .column {
        margin-top: 10px;
      }

      input {
        height: 40px;
      }
    }

    .checkbox {
      margin-top: 10px;
      width: 100%;
    }
  }

  .message {
    margin-bottom: 10px;
  }

  .buttonContainer {
    width: 100%;
  }
}

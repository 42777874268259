@import 'src/styles/services';

$text-shadow: 0 2px 1px rgba(lighten($color-grey, 20%), 50%);

.button {
  border: none;
  transition: trs(background-color, border-color, box-shadow, color, text-shadow);
  z-index: 1;
  -webkit-tap-highlight-color: transparent;

  > svg {
    color: inherit;
    transition-delay: 0s;
    transition-duration: 0.05s;
    transition-property: color;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

@mixin cutElements($color) {
  --offset: clamp(2.5px, 2.5%, 5px);

  box-shadow: none;
  transition: none;
  background-color: transparent;
  overflow: visible;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    background-color: $color;
    z-index: -1;
    transition: trs(transform, clip-path);
    left: 0;
  }

  &::before {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    top: 0;
    height: calc(50% + 1px);
    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
  }

  &::after {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    bottom: 0;
    height: 50%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }

  @include hover {
    box-shadow: none;
    background-color: transparent;

    &::before {
      opacity: 1;
      transform: translateX(var(--offset));
      clip-path: polygon(100% 0, 100% 75%, 75% 100%, 0 100%, 0 0);
    }

    &::after {
      opacity: 1;
      transform: translateX(calc(-1 * var(--offset)));
      clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%, 0% 25%);
    }
  }
}

/* Color variant */
$colors: (
  white: $color-white,
  grey: $color-grey,
  greyLight: $color-grey-light,
  blackLight: $color-black-light,
  red: $color-red,
  green: $color-green,
  yellow: $color-yellow,
  aviator: $color-aviator,
);

@each $name, $color in $colors {
  .#{$name}Color {
    color: $color;
  }
}

/* Text variant */
.text {
  background-color: transparent;

  @each $name, $color in $colors {
    &.#{$name}Color {
      color: $color;

      @include hover {
        text-shadow: $text-shadow;
      }

      &:active,
      &.active {
        color: $color;
      }

      &.disabled {
        color: $color-black-light;
      }
    }
  }
}

/* Lined variant */
.lined {
  padding: 10px 15px;

  &::before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 3px;
    transition: trs(background-color);
    z-index: 1;
  }

  @each $name, $color in $colors {
    &.#{$name}Color {
      color: $color-white;

      &::before {
        box-shadow: 0 2px 10px $color;
        background-color: $color;
      }

      @include hover {
        color: $color-grey-light;
        text-shadow: $text-shadow;

        &::before {
          background-color: $color;
        }
      }

      &.active,
      &:active {
        color: $color-grey;

        &::before {
          background-color: $color;
        }
      }

      &.disabled {
        color: $color-black-light;

        &::before {
          box-shadow: none;
          background-color: $color-black-light;
        }
      }
    }
  }
}

/* Outlined variant */
.outlined {
  background: transparent;

  @each $name, $color in $colors {
    &.#{$name}Color {
      color: $color-white;
      border: 1px solid $color;

      @include hover {
        text-shadow: $text-shadow;
        border-color: darken($color, 10%);
      }

      &.active,
      &:active {
        border: 1px solid darken($color, 10%);
        color: $color-grey;
      }

      &.disabled {
        color: $color-grey;
        background-color: $color-grey-2;
        border: 1px solid $color-black-light;
        box-shadow: none;
      }
    }
  }
}

/* Fulfilled variant */
.fulfilled {
  @mixin fulfilledColor($background, $color: $color-white) {
    color: $color;
    background-color: $background;
    box-shadow: 0 0 10px rgba($background, 60%);

    @include hover {
      text-shadow: $text-shadow;
    }

    &.active,
    &:active {
      background-color: darken($background, 10%);
    }

    &.disabled {
      color: $color-grey;
      background-color: $color-grey-2;
      box-shadow: none;

      &::before,
      &::after {
        background-color: $color-black-light;
      }
    }
  }

  $hovers: (
    grey: $color-grey-light,
    greyLight: $color-grey,
  );

  @each $name, $color in $colors {
    &.#{$name}Color {
      @if $name == white {
        @include fulfilledColor($color-white, $color-black);

        box-shadow: none;
      } @else {
        @include fulfilledColor($color);
      }

      @if map-has-key($hovers, $name) {
        @include hover {
          background-color: map-get($hovers, $name);
        }
      }

      &.cut:not(.disabled) {
        @include cutElements($color);
      }
    }
  }

  /* Skew variant */
  &.leftSkew {
    clip-path: polygon(0 17.5%, 125% 0, 100% 100%, -25% 82.5%);
  }

  /* Polygon inverted variant */
  &.rightSkew {
    clip-path: polygon(0 0, 125% 17.5%, 100% 82.5%, -25% 100%);
  }
}

@import 'src/styles/services';

.root {
  position: relative;

  .button {
    min-height: 40px;
    padding: 0 24px;
    flex-shrink: 0;
    max-width: 60%;
    white-space: pre-wrap;
    overflow: hidden;
  }

  .closeButton {
    color: $color-grey;
  }
}

.row {
  display: flex;
  align-items: center;
  background-color: $color-black-light;
  border-radius: $border-sm;
  padding-right: 16px;
}

.text {
  @include text(md, 500);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 16px;
  flex-grow: 1;
  color: $color-grey;
}

.description {
  @include text(sm, 500);

  color: $color-grey;
  margin-top: 4px;
  padding: 0 16px;
}

.invalidField {
  .row {
    border: 1px solid $color-red;
  }

  .text {
    color: $color-red;
  }
}

.error {
  @include text(sm, 500);

  margin-top: 4px;
  padding: 0 16px;
  color: $color-red;
}

.loaderWrapper {
  position: relative;
  width: 24px;
  height: 24px;
}

.loaderContainer {
  padding: 14px;
}

@include media-width-down($screen-xs) {
  .root {
    .button {
      padding: 0 16px;
      font-size: 14px;
    }
  }

  .text {
    padding: 0 8px;
  }
}

@import 'src/styles/services';

.root.root.root {
  &.root {
    padding: 8px;
    border-radius: 0;

    &::before {
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
    }

    @include hover {
      &::before {
        transform: translateX(calc(-1 * var(--offset)));
        clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 95%);
      }
    }

    &::after {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
    }

    @include hover {
      &::after {
        transform: translateX(var(--offset));
        clip-path: polygon(-35% 20%, 100% 0, 100% 100%, 0 90%);
      }
    }
  }
}

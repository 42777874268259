.switch_disabled {
  cursor: default;
  opacity: 0.5;
}

.switch__thumb {
  transition: trs(all);
  z-index: 0;

  .switch_disabled & {
    color: $color-grey;
    cursor: default;
    pointer-events: none;
  }
}

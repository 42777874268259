@import 'src/styles/services';

.root {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

@include media-width-down($screen-xs) {
  .root {
    font-size: 14px;
    line-height: 17px;
  }
}

// Variants
.errorVariant {
  color: $color-red;
}

@import 'src/styles/services';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tab {
  width: calc(33.3333% - 10px);
  user-select: none;

  &.mobile {
    display: none;
  }
}

.form {
  display: none;
  margin-top: 30px;

  &.active {
    display: block;
  }
}

.accountButton {
  margin-top: 20px;
  background: #151f2b;
}

@include media-width-down($screen-sm) {
  .root {
    width: 100%;
  }
}

@include media-width-down($screen-xs) {
  .form {
    margin-top: 20px;
  }

  .header {
    width: calc(100% + 10px);
    margin: -10px -5px 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .tab {
    margin: 10px 5px 0;
    width: calc(50% - 10px);
    flex-grow: 1;
    user-select: none;

    &.desktop {
      display: none;
    }

    &.mobile {
      display: flex;
    }
  }

  .buttonWithoutAccount {
    margin-top: 20px;
  }
}

@import 'src/styles/services';

.root {
  @include text(xs);

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  gap: 16px;
}

.centerAlign {
  text-align: center;
  justify-content: center;
}

.tooltip {
  color: $color-white;
}

.label {
  flex-shrink: 2;
  word-break: break-word;
  color: $color-grey-light;
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: $color-black;
  margin-bottom: 8px;
}

.valueContainer {
  display: flex;
  align-items: center;
  flex-shrink: 1;
}

.copyButton {
  margin-right: 8px;
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.value {
  width: 100%;
  text-align: center;
}

.jivo {
  color: $color-grey-light;

  .link {
    color: $color-red;
    text-decoration: underline;
  }
}

@include media-width-down($screen-1032) {
  .root {
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .value {
    overflow-wrap: anywhere;
  }
}

@import 'src/styles/services';

.root {
  background-color: #f0f4fc;
  border-radius: $border-lg;

  .content {
    padding: 16px 16px 14px;

    .submit {
      margin-top: 8px;

      &:before,
      &:after {
        background-color: #007b00;
      }
    }
  }
}

.limits {
  margin-bottom: 8px;
  color: $color-grey;
}

.range {
  color: $color-red;
}

.footer {
  position: relative;
  padding: 16px;

  &:after {
    content: '';
    background-color: $color-black;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 10%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10px;
  }
}

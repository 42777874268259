@import '~@mwl/ui/dist/scss/variables';

/* Adaptive */
$screen-360: 360px;
$screen-xxs: 375px;
$screen-xs: 768px;
$screen-850: 850px;
$screen-sm: 1024px;
$screen-1032: 1032px;
$screen-1100: 1100px;
$screen-1274: 1274px;
$screen-1284: 1284px;
$screen-md: 1366px;
$screen-1550: 1550px;
$screen-lg: 1600px;
$screen-xl: 1920px;
$screen-xxl: 2100px;

/* Borders */
$border-sm: 5px;
$border-md: 8px;
$border-lg: 10px;

$mwl-radius-sm: 5px;
$mwl-radius-md: 8px;
$mwl-radius-lg: 10px;

/* Z-index */
$mobile-float-menu-z-index: 5;
$mobile-menu-z-index: 10;
$header-button-z-index: 11;
$header-z-index: 13;
$sidebar-z-index: 14;
$mobile-menu-center-button-z-index: 15;
$tippy-z-index: 16;
$popup-z-index: 17;
$payment-popup-tippy-z-index: 18;
$slider-shadow-z-index: 20;
$auth-with-wheel-z-index: 1000;
$preloader-z-index: $auth-with-wheel-z-index + 1;
$basic-auth-z-index: $auth-with-wheel-z-index + 2;

/* Colors */
$color-red: #ff0000;
$color-red-light: #f55050;
$color-red-light-2: #ff4a4a;
$color-black: #141414;
$color-white: #ffffff;
$color-yellow: #ffa902;
$color-orange: #ffa500;
$color-yellow-light: #fcf501;
$color-green: #43d31f;
$color-green-light: #70ff00;
$color-green-soft: #2fc072;
$color-black-lighter: rgba(41, 44, 51, 0.6);
$color-black-lighter-2: rgba(41, 44, 51, 0.1);
$color-black-light: #292c33;
$color-grey: #5a5d64;
$color-grey-light: #a0a4ac;
$color-grey-dark: #454545;
$color-grey-2: #353537;
$color-pink: #ec6db2;
$color-blue: #338af3;
$color-blue-dark: #6533f3;
$color-violet: #fa00ff;
$color-aviator: #e63f6d;
$color-android: #a4c639;
$color-transparency-white-60: #ffffff99;

/* Other */
$shadow:
  -3px -3px 7px rgba($color-grey, 0.4),
  3px 3px 7px rgba($color-black, 0.4);
$shadow-inset:
  $shadow,
  inset 1px 1px 4px $color-black;
$shadow-red: 0 4px 16px 0 rgba($color-red, 0.5);

$header-height: 122px;
$mobile-coupon-padding-top: 52px;

/* MobileMenu height */
$mobile-menu-height-xs: 58px;
$mobile-menu-with-button-height-xs: 58px;

@import 'src/styles/services';

.root {
  position: relative;
  flex-direction: column;
  width: 100%;
}

.description {
  margin-top: 23px;
  font-size: 18px;
  line-height: 22px;
}

.button {
  @include text(md, 700);
}

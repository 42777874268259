@import 'src/styles/services';

.cardFooter {
  display: flex;
  flex-direction: column;

  .minValue {
    color: $color-yellow;
  }
}
